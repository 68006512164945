import defaultLocations from '~/data/default_locations.json';
import type {
    ClassifiedAdCategory,
    ClassifiedAdFeaturedPackage,
    ClassifiedAdFeaturedPackageOrder,
    ClassifiedAdItemCondition,
    ClassifiedAdQuota,
    ClassifiedAdReportType,
    DashboardClassifiedAd
} from '~/types';
interface ClassifiedState {
    selected_classified_ad_category: object | null,
    current_dashboard_classified_ad: DashboardClassifiedAd | null,
    current_dashboard_classified_ad_expiry: number|null,
    current_dashboard_classified_ad_images: string[],
    current_dashboard_classified_ad_images_expiry: number|null,
    current_dashboard_classified_ad_orders: ClassifiedAdFeaturedPackageOrder[],
    current_dashboard_classified_ad_orders_expiry: number|null,
    classified_ad_categories: ClassifiedAdCategory[]
    classified_ad_categories_expiry: number|null,
    classified_ad_item_conditions: ClassifiedAdItemCondition[]
    classified_ad_item_conditions_expiry: number|null,
    classified_ad_report_types: ClassifiedAdReportType[],
    classified_ad_report_types_expiry: number|null,
    classified_ad_quota: ClassifiedAdQuota|null,
    classified_ad_quota_expiry: number|null,
    classified_ad_featured_packages: ClassifiedAdFeaturedPackage[],
    classified_ad_featured_packages_expiry: number|null,
    caching_time: number;
}
export const useClassified = defineStore('classified', {
    state: (): ClassifiedState => {
        return {
            selected_classified_ad_category: null,

            current_dashboard_classified_ad: null,
            current_dashboard_classified_ad_expiry: null,
            current_dashboard_classified_ad_images:[],
            current_dashboard_classified_ad_images_expiry:null,
            current_dashboard_classified_ad_orders:[],
            current_dashboard_classified_ad_orders_expiry:null,
            classified_ad_categories: [],
            classified_ad_categories_expiry: null,
            classified_ad_item_conditions: [],
            classified_ad_item_conditions_expiry: null,
            classified_ad_report_types: [],
            classified_ad_report_types_expiry: null,
            classified_ad_quota: null,
            classified_ad_quota_expiry: null,
            classified_ad_featured_packages: [],
            classified_ad_featured_packages_expiry: null,
            caching_time: 1000*10,
        }
    },
    actions: {
        setClassifiedAdCategory(classified_ad_category: object) {
            this.selected_classified_ad_category = classified_ad_category;
        },
        clearClassifiedAdCategory() {
            this.selected_classified_ad_category = null;
        },

        setCurrentDashboardClassifiedAd(dashboardClassifiedAd: DashboardClassifiedAd) {
            this.current_dashboard_classified_ad = dashboardClassifiedAd;
            this.current_dashboard_classified_ad_expiry = Date.now();
        },
        clearCurrentDashboardClassifiedAd() {
            this.current_dashboard_classified_ad = null;
            this.current_dashboard_classified_ad_expiry = null;
        },
        isCurrentDashboardClassifiedAdValid(){
            if(this.current_dashboard_classified_ad_expiry !=null){
                return ((Date.now() - this.current_dashboard_classified_ad_expiry) < this.caching_time);
            }
            return true;
        },
        updateFeaturedUntilDateOfCurrentDashboardClassifiedAd(featured_until: string, featured_until_formatted:string) {
            if(this.current_dashboard_classified_ad){
                this.current_dashboard_classified_ad.featured_until = featured_until;
                this.current_dashboard_classified_ad.featured_until_formatted = featured_until_formatted;
            }
        },
        setCurrentDashboardClassifiedAdImages(imageUrls: string[]){
            this.current_dashboard_classified_ad_images = imageUrls;
            this.current_dashboard_classified_ad_images_expiry = Date.now();
        },
        clearCurrentDashboardClassifiedAdImages(){
            this.current_dashboard_classified_ad_images = [];
            this.current_dashboard_classified_ad_images_expiry = null;
        },
        isCurrentDashboardClassifiedAdImagesValid(){
            if(this.current_dashboard_classified_ad_images_expiry !=null){
                return ((Date.now() - this.current_dashboard_classified_ad_images_expiry) < this.caching_time);
            }
            return true;
        },
        addImageToCurrentDashboardClassifiedAdImages(imageUrl: string){
            this.current_dashboard_classified_ad_images.push(imageUrl);
            this.current_dashboard_classified_ad?.images.push(imageUrl);
        },
        removeImageFromCurrentDashboardClassifiedAdImagesAtIndex(index: number){
            this.current_dashboard_classified_ad_images.splice(index, 1);
            this.current_dashboard_classified_ad?.images.splice(index, 1);
        },
        
        setCurrentDashboardClassifiedAdOrders(orders: ClassifiedAdFeaturedPackageOrder[]){
            this.current_dashboard_classified_ad_orders = orders;
            this.current_dashboard_classified_ad_orders_expiry = Date.now();
        },
        clearCurrentDashboardClassifiedAdOrders(){
            this.current_dashboard_classified_ad_orders = [];
            this.current_dashboard_classified_ad_orders_expiry = null;
        },
        addOrderToCurrentDashboardClassifiedAdOrders(order: ClassifiedAdFeaturedPackageOrder){
            this.current_dashboard_classified_ad_orders.push(order);
        },
        updateOrderOfCurrentDashboardClassifiedAd(order: ClassifiedAdFeaturedPackageOrder){
            const orderIndex = this.current_dashboard_classified_ad_orders.findIndex(adOrder => {
                return adOrder.id === order.id;
              });
              this.current_dashboard_classified_ad_orders[orderIndex] = order;
        },
        isCurrentDashboardClassifiedAdOrdersValid(){
            if(this.current_dashboard_classified_ad_orders_expiry !=null){
                return ((Date.now() - this.current_dashboard_classified_ad_orders_expiry) < this.caching_time);
            }
            return true;
        },
        setClassifiedAdCategories(classifiedAdCategories: any) {
            this.classified_ad_categories = classifiedAdCategories;
            this.classified_ad_categories_expiry = Date.now();
        },
        clearClassifiedAdCategories() {
            this.classified_ad_categories = [];
            this.classified_ad_categories_expiry = null;
        },
        isClassifiedAdCategoriesValid(){
            if(this.classified_ad_categories_expiry !=null){
                return ((Date.now() - this.classified_ad_categories_expiry) < this.caching_time);
            }
            return true;
        },
        setClassifiedAdItemConditions(classifiedAdItemConditions: any) {
            this.classified_ad_item_conditions = classifiedAdItemConditions;
            this.classified_ad_item_conditions_expiry = Date.now();
        },
        clearClassifiedAdItemConditions() {
            this.classified_ad_item_conditions = [];
            this.classified_ad_item_conditions_expiry = null;
        },
        isClassifiedAdItemConditionsValid(){
            if(this.classified_ad_item_conditions_expiry !=null){
                return ((Date.now() - this.classified_ad_item_conditions_expiry) < this.caching_time);
            }
            return true;
        },

        setClassifiedAdReportTypes(classifiedAdReportTypes: any) {
            this.classified_ad_report_types = classifiedAdReportTypes;
            this.classified_ad_report_types_expiry = Date.now();
        },
        clearClassifiedAdReportTypes() {
            this.classified_ad_report_types = [];
            this.classified_ad_report_types_expiry = null;
        },
        isClassifiedAdReportTypesValid(){
            if(this.classified_ad_report_types_expiry !=null){
                return ((Date.now() - this.classified_ad_report_types_expiry) < this.caching_time);
            }
            return true;
        },

        setClassifiedAdQuota(classifiedAdQuota: any) {
            this.classified_ad_quota = classifiedAdQuota;
            this.classified_ad_quota_expiry = Date.now();
        },
        clearClassifiedAdQuota() {
            this.classified_ad_quota = null;
            this.classified_ad_quota_expiry = null;
        },
        isClassifiedAdQuotaValid(){
            if(this.classified_ad_quota_expiry !=null){
                return ((Date.now() - this.classified_ad_quota_expiry) < this.caching_time);
            }
            return true;
        },
        setClassifiedAdFeaturedPackages(classifiedAdFeaturedPackages: ClassifiedAdFeaturedPackage[]) {
            this.classified_ad_featured_packages = classifiedAdFeaturedPackages;
            this.classified_ad_featured_packages_expiry = Date.now();
        },
        clearClassifiedAdFeaturedPackages() {
            this.classified_ad_featured_packages = [];
            this.classified_ad_featured_packages_expiry = null;
        },
        isClassifiedAdFeaturedPackagesValid(){
            if(this.classified_ad_featured_packages_expiry !=null){
                return ((Date.now() - this.classified_ad_featured_packages_expiry) < this.caching_time);
            }
            return true;
        },
    },
})